<template>
    <div class="page players-page" @keyup.13="onFindPlayerClick">
        <div class="page__title">
            <nav-back/>
            Найти игрока
        </div>
        <div class="players-page__search">
            <label class="mr-2 mt-1 text-left">ID игрока</label>
            <input type="text" v-model="userId" class="form-control"/>
            <ui-button color="blue" @clicked="onFindPlayerClick" class="players-page__search-button">Найти</ui-button>
        </div>
        <div v-if="userData" class="d-flex">
            <ui-button color="blue" class="mt-3" @clicked="onDropUserClick">Сбросить данные игрока</ui-button>
        </div>
        <div v-if="userData" class="mt-4 text-left">
            {{ JSON.stringify(userData, null, 2) }}
        </div>
    </div>
</template>

<script>
import {mapActions} from 'vuex';
import bus from '@/plugins/bus';

export default {
    data: () => ({
        userId: '',
        userData: null,
    }),
    components: {
        UiButton: () => import('../../components/ui/UiButton'),
        NavBack: () => import('../../components/navigation/NavBack'),
    },
    async created() {
        await this.getConfig();
    },
    methods: {
        ...mapActions('firebase', ['getConfig', 'getDatabaseUser', 'dropDatabaseUser']),
        async onFindPlayerClick() {
            if (this.userId.length === 0) return;
            this.userData = null;

            const response = await this.getDatabaseUser(this.userId);

            if (!response.error) {
                this.userData = response;
            } else {
                bus.$emit('show-notification-message', `Ошибка: ${response.error.toString()}`);
            }
        },
        async onDropUserClick() {
            const response = await this.dropDatabaseUser(this.userId);

            if (!response.error) {
                bus.$emit('show-notification-message', 'Пользователь успешно сброшен');
                this.userData = null;
            } else {
                bus.$emit('show-notification-message', `Ошибка: ${response.error.toString()}`);
            }
        }
    }
};
</script>

<style lang="scss">
.players-page {
    &__search {
        display: flex;
        flex-direction: column;
    }

    &__search-button {
        margin-top: 12px;
        width: max-content;
    }
}
</style>
